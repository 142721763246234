import React from "react";
import "./App.css";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FeatureCard from "./components/FeatureCard";
import "typeface-roboto";

const useStyles = makeStyles(theme => ({
  app: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "#f8faff",
    backgroundImage: "url(hero.PNG)",
    backgroundSize: "cover",
    opacity: 1.0
  },
  grid: {
    flexDirection: "row",
    justifyContent: "center",
    margin: 0,
    minHeight: "100vh",
    opacity: 1.0
  },
  cell: {
    display: "flex",
    justifyContent: "center"
  },
  fontMain: {
    fontWeight: "bold",
    color: "white"
  },
  fontFooter: {
    color: "white"
  }
}));

function App() {
  const textColor = "#f5fffe";

  const classes = useStyles();
  const label1 = "Physical mockups at your disposal";
  const label2 = "Skill acquistion at your own pace";
  const label3 = "Steep learning curve, not price";

  const text1 =
    "Get real, hands-on training on approved dry and wet lab models";

  const text2 =
    "Practice from the comfort of your home, whenever you like, for as long as you like";

  const text3 =
    "Pay as you go on a per-hour basis at 1/10th of the rates of hands-on courses";

  return (
    <div className={classes.app}>
      <Grid container className={classes.grid}>
        <Grid item xs={12}>
          <div width={"100%"} />
        </Grid>
        <Grid item xs={2}>
          <div width={"100%"} />
        </Grid>
        <Grid item xs={8} className={classes.cell}>
          <div>
            <Typography
              variant="h2"
              gutterBottom
              align="center"
              className={classes.fontMain}
            >
              Robotic Surgery Training. Solved.
            </Typography>

            <br />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div width={"100%"} />
        </Grid>
        <Grid item xs={12}>
          <div width={"100%"} />
        </Grid>
        <FeatureCard label={label1} text={text1} color={textColor} />
        <FeatureCard label={label2} text={text2} color={textColor} />
        <FeatureCard label={label3} text={text3} color={textColor} />
        <Grid item xs={12} className={classes.cell}>
          <Typography
            variant="body1"
            gutterBottom
            align="left"
            className={classes.fontFooter}
          >
            We are currently running in stealth mode but we'd love to hear from
            you. Drop us a line at{" "}
            <a href="mailto:info@xafatech.com">info@xafatech.com</a>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
