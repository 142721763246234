import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import "typeface-roboto";

const useStyles = makeStyles(theme => ({
  cell: {
    display: "flex"
  },
  fontTitle: {
    color: "white",
    fontWeight: "bold"
  },
  fontBody: {
    color: "white"
  }
}));

function FeatureCard({ label, text, color }) {
  const classes = useStyles();
  return (
    <Grid item md={4} sm={4} xs={12} className={classes.cell}>
      <Grid item xs={1} className={classes.cell}></Grid>
      <Grid item xs={10} className={classes.cell}>
        <div>
          <Typography
            variant="h5"
            gutterBottom
            align="left"
            className={classes.fontTitle}
          >
            {label}
          </Typography>
          <br />
          <Typography
            variant="h5"
            gutterBottom
            align="left"
            className={classes.fontBody}
          >
            {text}
          </Typography>
          <br />
          <br />
          <br />
        </div>
      </Grid>
      <Grid item xs={1} className={classes.cell}></Grid>
    </Grid>
  );
}

export default FeatureCard;
